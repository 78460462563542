import type { Ref } from "vue";
import { useCalculatorApi } from "~/api/useCalculatorApi";

interface UseTechResult {
  time: Ref<number>;
  on: Ref<boolean>;
}

export function useTech(): UseTechResult {
  const time = ref(0);
  const on = ref(false);

  const { fetchTech } = useCalculatorApi();

  async function getTech() {
    try {
      const { technicalMode } = await fetchTech();
      const { time: timeValue, on: onValue } = technicalMode;
      time.value = timeValue;
      on.value = onValue;
    } catch (error) {
      console.log(error);
    }
  }

  useIntervalFn(
    async () => {
      await getTech();
    },
    10000,
    { immediateCallback: true },
  );

  return {
    time,
    on,
  };
}
